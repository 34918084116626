.heroBanner {
    background-color: #F4F4F4;
    padding: 110px 10px;

    &__title {
        font: italic normal bold 70px/94px Playfair Display;
        letter-spacing: 0px;
        color: #333333;
    }

    &__btnGroup {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
        margin-top: 30px;
    }

    &__btn {
        background: #0176A8;
        border-radius: 2px;
        padding: 6px 22px;
        border: none;
        color: #fff;
        font: normal normal bold 18px/22px Garamond Premier Pro;
        transition: all 0.2s ease;

        &:hover {
            background-color: #40CFD5;
        }
    }

    &__img {
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        height: 100%;
        display: flex;
        width: 125%;
        margin-left: -25%;

        img {
            width: 100%;
            max-width: inherit;
            object-fit: contain;
        }
    }
}

.newsBlock {
    padding: 130px 10px;

    &__heading {
        margin-bottom: 55px;
        text-align: center;
    }

    &__subTitle {
        font: normal normal normal 16px/19px Lato;
        letter-spacing: 1.6px;
        color: #333333;
        text-transform: uppercase;
        margin: 0 0 12px;
    }

    &__title {
        font: italic normal bold 50px/58px Playfair Display;
        letter-spacing: 0px;
        color: #333333;
        margin: 0;
    }
}

.newsCard {
    background-color: #F4F4F4;
    box-shadow: 0px 3px 6px #00000029;
    transition: all 0.2s ease;
    height: 100%;
    cursor: pointer;

    &__img {
        background-color: #ffff;
        width: 100%;
        height: 373px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__content {
        padding: 30px;

        p {
            font: normal normal normal 16px/19px Lato;
            letter-spacing: 0px;
            color: #333333;
            margin-bottom: 15px;
        }
    }

    &__title {
        font: normal normal bold 23px/33px Garamond Premier Pro;
        letter-spacing: 0px;
        color: #333333;
    }

    &__date {
        font: normal normal bold 15px/19px Lato;
        letter-spacing: 0px;
        color: #0176a8;
        margin-top: 25px;
    }

    &__btn {
        text-decoration: underline;
        font: normal normal bold 16px/19px Lato;
        letter-spacing: 0px;
        color: #BD4F19;
        cursor: pointer;
    }

    &:hover {
        box-shadow: 0px 5px 10px #00000065;
    }
}

.contentMediaBlock {
    padding: 110px 10px;
    background-color: #F4FAFC;

    &__subTitle {
        font: normal normal normal 16px/19px Lato;
        letter-spacing: 1.6px;
        color: #333333;
        text-transform: uppercase;
        margin: 0 0 12px;
    }

    &__title {
        font: italic normal bold 50px/60px Playfair Display;
        letter-spacing: 0px;
        color: #333333;
        margin: 0 0 25px;
    }

    &__text {
        p {
            font: normal normal normal 16px/19px Lato;
            letter-spacing: 0px;
            color: #333333;
            max-width: 500px;
            margin: 0;
        }
    }

    &__btn {
        background: #BD4F19;
        border-radius: 3px;
        text-align: center;
        font: normal normal bold 20px/25px Lato;
        letter-spacing: 2.1px;
        color: #FFFFFF;
        text-transform: uppercase;
        padding: 10px 25px;
        border: none;
        margin: 35px 0 0;
        transition: all 0.2s ease;

        &:hover {
            background-color: #FF6D21;
            color: #000;
        }
    }

    &__img {
        max-width: 550px;
        margin: 0 auto;

        img {
            width: 100%;
        }
    }
}

.contentMediaModule {
    .contentMediaBlock:nth-child(even) {
        .contentMediaBlock__img {
            order: 1;
        }
    }
}

@media (min-width: 1400px) {
    .container {
        max-width: 1200px;
    }
}


@media (max-width: 1400px) {
    .heroBanner__title {
        font-size: 58px;
        line-height: 76px;
    }

    .newsBlock {
        padding: 110px 10px;
    }
}

@media (max-width: 991px) {
    .heroBanner {
        padding: 80px 10px;

        &__title {
            font-size: 46px;
            line-height: 60px;
        }

        &__img img {
            width: 100%;
            margin-left: 0;
        }

        &__btnGroup {
            gap: 10px;
            margin: 30px 0;
        }
    }

    .newsBlock {
        padding: 80px 10px;

        &__subTitle {
            margin: 0 0 5px;
        }

        .newsBlock__title {
            font-size: 40px;
            line-height: 55px;
        }

        &__heading {
            margin-bottom: 30px;
        }
    }

    .newsCard {
        margin: 15px 0;
        height: calc(100% - 30px);

        &__content {
            padding: 25px;
        }

        &__img {
            background-color: #fff;
            ;
            width: 100%;
            height: 320px;
        }
    }

    .contentMediaBlock {
        padding: 80px 10px;

        &__title {
            font-size: 40px;
            line-height: 55px;
        }

        &__img {
            margin-bottom: 30px;
            order: 0 !important;
        }

        &__btn {
            font-size: 16px;
            line-height: 20px;
        }
    }
}

@media (max-width: 767px) {
    .heroBanner__title {
        font-size: 40px;
        line-height: 55px;
    }
}