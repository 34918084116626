.siteHeader {
    .custom-header {
        background-color: #fff;
        box-shadow: unset;

        .showMobileMenu {
            padding: 0 !important;
        }
    }

    .top-navbar {
        background: linear-gradient(90deg, rgba(189, 79, 25, 1) 0%, rgba(104, 52, 49, 1) 100%, rgba(104, 52, 49, 1) 100%);
        text-align: right;
        margin-left: auto;
        padding: 5px;

        button {
            background: transparent !important;
            border-color: transparent !important;
            padding: 0 !important;
            min-width: unset !important;
        }

        .MuiButtonGroup-root {
            box-shadow: none !important;
        }
    }

    .mobile-topbar {
        display: none;
    }

    .header-logo {
        display: block;
        max-width: 200px;
        width: 100%;
        margin-left: 15px;
    }

    .navbar-right {
        text-align: right;
        padding-right: 20px;
        display: block;

        .nav-link {
            color: #333;
            padding: 15px 0px;
            min-width: unset;
            font-family: 'Lato', sans-serif;
            font-weight: 700;
            letter-spacing: 1px;
            position: relative;
            font: normal normal normal 16px/19px Lato;
            letter-spacing: 1.6px;
            color: #333333;
            text-transform: uppercase;

            &:last-child {
                padding-right: 0;
            }

            &::after {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                bottom: 0;
                background-color: #333333;
                height: 5px;
                width: 0%;
                transition: all 0.2s ease;
                border-radius: 12px;
            }

            &:hover {
                background-color: transparent !important;

                &::after {
                    width: 100%;
                }
            }

            &.activeMenu {
                background-color: transparent !important;

                &::after {
                    width: 100%;
                }
            }
        }
    }

    .mobileToggleMenu {
        display: none;
    }

    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 !important;
    }

    &__menuBlock {
        width: 50vw;
    }
}

.desktopMenu {
    display: flex;
    gap: 40px;
    justify-content: flex-end;
}

@media only screen and (max-width: 1400px) {
    .siteHeader {
        .desktopMenu {
            gap: 25px;
        }

        .navbar-right .nav-link {
            font: normal normal normal 14px/19px Lato;
        }
    }

}

@media only screen and (max-width: 991px) {
    .siteHeader {

        .mobile-topbar {
            display: block;
            width: 100%;
        }

        .header-logo {
            max-width: 140px;
        }

        .navbar-right {
            display: none;
        }

        .mobileToggleMenu {
            display: flex;
            margin-left: auto;
            margin-right: 0;
            background: linear-gradient(90deg, rgb(189, 79, 25) 0%, rgb(104, 52, 49) 100%, rgb(104, 52, 49) 100%);
            padding: 0;
            border-radius: 6px;
            margin-right: 15px;

            .showMobileMenubtn {
                padding: 10px;
                display: flex;
            }
        }

        .mobileToggleMenu.showMobileMenu~.navbar-right {
            display: block;
            position: absolute;
            top: 100%;
            background: #fff;
            max-width: 100%;
            border-radius: 0;
            padding: 15px;
            width: 100%;
            left: 0;
            right: 0;
            z-index: 11;
            border-top: 5px solid #803c2b;
            border-bottom: 1px solid #803c2b;

            .desktopMenu {
                display: flex;
                flex-direction: column;
                gap: 15px;

                .nav-link {
                    padding: 5px;
                    justify-content: flex-start;
                }
            }
        }

        .css-hip9hq-MuiPaper-root-MuiAppBar-root {
            padding: 10px 0;
        }
    }

    .desktop-topbar {
        display: none;
    }
}

.loaderModule {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: #ffffff69;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
}

.loaderModule svg {
    fill: aqua;
    height: 50px;
    width: 50px;
}

.productImage {
    height: 250px;
    width: 250px;
    margin: 0 auto;
    border-radius: 10px;
    object-fit: cover;
    overflow: hidden;
}

.dropDownMenuBlock {
    position: absolute;
    background: #fff;
    top: 100%;
    left: 0;
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__title {
        font-size: 20px;
        color: #000;
        margin: 0 0 20px;
        font-style: italic;
        font-weight: 700;
    }

    &__link {
        font-size: 16px !important;
        color: #000 !important;
        font-weight: 600 !important;
        text-transform: capitalize !important;
        padding: 5px 0 !important;
    }
}

.serviceList {
    margin: 60px 0;

    &:empty {
        display: none;
    }

    &__img {
        max-width: 400px;
        width: 100%;
        height: 200px;
        background: #E6F4F6 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;

        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }

    &__info {
        text-align: left;
    }

    &__title {
        font: var(--unnamed-font-style-italic) normal var(--unnamed-font-weight-bold) 40px/53px var(--unnamed-font-family-playfair-display);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-333333);
        font: italic normal bold 40px/53px Playfair Display;
        letter-spacing: 0px;
        color: #333333;
    }

    &__text {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-lato);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-333333);
        font: normal normal normal 16px/19px Lato;
        letter-spacing: 0px;
    }

    &__link {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-lato);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-bd4f19);
        text-decoration: underline;
        font: normal normal bold 16px/19px Lato;
        letter-spacing: 0px;
        color: #BD4F19;
        cursor: pointer;
    }
}

.serviceListBlock {
    padding: 60px 0;

    .serviceList:nth-child(even) {
        .serviceList__imgBlock {
            order: 1;
        }

        .serviceList__info {
            text-align: right;
        }
    }
}