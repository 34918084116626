.text-right {
    text-align: right !important;
}

.footer {
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(67, 33, 31, 1) 74%, rgba(67, 33, 31, 1) 100%);
    color: #bbbbbb;

    .footer-top-part {
        padding: 15% 0;

        h4 {
            margin-bottom: 25px;

            a {
                text-decoration: none;
                color: #bbb;

                &:hover {
                    color: #fff;
                }
            }
        }

        ul {
            padding: 0;
            list-style-type: none;

            a {
                display: inline-block;
                color: #bbb;
                text-decoration: none;
                text-transform: capitalize;
                margin-bottom: 10px;
                line-height: normal;

                &:hover {
                    color: #fff;
                }
            }
        }

        .social-icon {
            button {
                width: 60px !important;
                height: 60px !important;
                padding: 6px;
                background-color: #683431;
                border-radius: 5px;
                margin: 0 5px;
                cursor: pointer;

                svg {
                    fill: #000 !important;
                    height: 40px;
                    width: 40px;
                }

                &:hover {
                    background-color: #bd4f19 !important;
                }
            }
        }
    }

    .footer-bottom-part {
        background-color: #222222;
        color: #bbbbbb;
    }
}