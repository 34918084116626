.newsPage {
    background: #F4F4F4;
    padding: 140px 20px;

    .MuiTabs-root {
        min-height: 0;
        margin: 0 0 60px;

        button {
            font: normal normal normal 16px/19px Lato;
            letter-spacing: 1.6px;
            color: #333333;
            text-transform: uppercase;
            padding: 5px 10px;
            min-width: 0;
            height: auto;
            min-height: 0;

            &.Mui-selected {
                color: #000;
                font-weight: 800;
            }

            &:focus {
                background: transparent;
            }

            .MuiTouchRipple-root {
                display: none;
            }
        }

        .MuiTabs-indicator {
            display: none;
        }
    }

    .MuiBox-root {
        padding: 0 !important;
    }

    .contentModule {
        padding: 0;

        &__inner {
            margin-bottom: 110px;
        }
    }
}

@media (max-width: 1400px) {
    .newsPage {
        padding: 110px 10px;
    }
}

@media (max-width: 991px) {
    .newsPage {
        padding: 80px 10px;

        .MuiTabs-root {
            margin: 0 0 22px;

            .MuiTabs-scroller {
                overflow-x: auto !important;
            }
        }

        .contentModule {
            padding: 0;

            &__inner {
                margin-bottom: 80px;
            }
        }
    }
}