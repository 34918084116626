@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap');

/* 
font-family: 'Lato', sans-serif;
font-family: 'Playfair Display', serif;
*/
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.MuiSnackbar-root .MuiSnackbarContent-root {
  background: green;
}

/* SetPass Model UI design */
.setPassModel>.MuiBox-root {
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);
  border: none;
  border-radius: 12px;
  top: 100px;
  margin-top: 13px;
}

.setPassModel>.MuiBox-root .MuiFormControl-root {
  width: 100%;
}

.setPassModel>.MuiBox-root .MuiFormControl-root fieldset {
  border: 1px solid #d6d9db;
}

.setPassModel>.MuiBox-root button {
  background: #175e78;
  color: #fff;
  padding: 8px 30px;
  font-weight: 500;
}

.subMenu {
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 13%);
  background: #fff;
  border-radius: 12px;
  padding: 20px;
  margin-top: 10px;
  position: absolute;
  top: 100%;
}

.app {
  display: flex;
  justify-content: center;
  text-align: center;
}

.todo_input {
  height: 16px;
  padding: 10px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border: 2px solid blueviolet;
}

.todo_button {
  border: 2px solid blueviolet;
  background-color: transparent;
  height: 40px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.todo {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  font-weight: 700;
  margin-bottom: 5px;
  min-width: 340px;
}

.edit {
  width: 66px;
  font-weight: 700;
  background: blueviolet;
  border: none;
  border-top-right-radius: 5px;
  height: 33px;
  border-bottom-right-radius: 5px;
  color: white;
  font-size: medium;
}

.delete {
  width: 66px;
  font-weight: 700;
  background: white;
  border: 2px solid blueviolet;
  border-top-left-radius: 5px;
  height: 33px;
  color: blueviolet;
  border-bottom-left-radius: 5px;
  font-size: medium;
}

.form {
  padding-top: 27px;
  padding-bottom: 27px;
}

.name {
  max-width: 190.34px;
  text-align: left;
}

.loaderModule {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: #ffffff69;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
}

.loaderModule svg {
  fill: aqua;
  height: 50px;
  width: 50px;
}

.loading {
  font-size: 84px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  text-align: center;

}

.load {
  position: absolute;
  width: 600px;
  height: 36px;
  left: 50%;
  top: 40%;
  margin-left: -300px;
  overflow: visible;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}

// Mixins
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }

  @-moz-keyframes #{$animation-name} {
    @content;
  }

  @-ms-keyframes #{$animation-name} {
    @content;
  }

  @-o-keyframes #{$animation-name} {
    @content;
  }

  @keyframes #{$animation-name} {
    @content;
  }
}


/*
    Example usage: 
    @include animation(10s, 5s, changecolour)
 */
@mixin animation($animation, $duration: 2000ms, $easing: ease-out, $count: infinite, $delay: 0ms) {
  -webkit-animation-delay: $delay;
  -webkit-animation-duration: $duration;
  -webkit-animation-timing-function: $easing;
  -webkit-animation-name: $animation;
  -webkit-animation-fill-mode: forwards;
  /* this prevents the animation from restarting! */
  -webkit-animation-iteration-count: $count;

  -moz-animation-delay: $delay;
  -moz-animation-duration: $duration;
  -moz-animation-timing-function: $easing;
  -moz-animation-name: $animation;
  -moz-animation-fill-mode: forwards;
  /* this prevents the animation from restarting! */
  -moz-animation-iteration-count: $count;

  -o-animation-delay: $delay;
  -o-animation-duration: $duration;
  -o-animation-timing-function: $easing;
  -o-animation-name: $animation;
  -o-animation-fill-mode: forwards;
  /* this prevents the animation from restarting! */
  -o-animation-iteration-count: $count;

  animation-delay: $delay;
  animation-duration: $duration;
  animation-timing-function: $easing;
  animation-name: $animation;
  animation-fill-mode: forwards;
  /* this prevents the animation from restarting! */
  animation-iteration-count: $count;
}



// Vars
$boundW : 26px;
$boundY : 20px;
$offset : 4px;

.loaderBox {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgb(0 0 0 / 60%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;
}

// Animation
@include keyframes(leftWall) {
  10% {
    transform: rotate(0);
  }

  20%,
  100% {
    transform: rotate(-90deg);
    transform-origin: top left;
  }
}

@include keyframes(rightWall) {
  20% {
    transform: rotate(0);
  }

  40%,
  100% {
    transform: rotate(90deg);
    transform-origin: top right;
  }
}

@include keyframes(roofLeft) {
  40% {
    transform: rotate(0);
    left: 0;
    bottom: 0;
  }

  60%,
  100% {
    transform: rotate(-35deg);
    transform-origin: bottom left;
    left: -$offset - 3px;
    bottom: 100%;
  }
}

@include keyframes(roofRight) {
  60% {
    transform: rotate(0);
    left: 0;
    bottom: 0;
  }

  80%,
  100% {
    transform: rotate(35deg);
    transform-origin: bottom right;
    left: $offset + 3px;
    bottom: 100%;
  }
}


// Loading Icon
.loading-container {
  display: inline-block;

  >span {
    font-size: 14px;
    text-align: center;
    display: inline-block;
    color: #1ecad3;
  }

  .loading__blocks {
    position: relative;
    width: $boundW;
    height: $boundY;
    margin: 0 auto;

    .line {
      position: absolute;
      left: 0;
      bottom: 0;
      width: $boundW;
      border-top: 3px solid #ccc;
      border-radius: 2px;

      &--bottom {}

      &--left {
        @include animation(leftWall);
      }

      &--right {
        @include animation(rightWall);
      }

      &--roof-left {
        width: $boundW;
        @include animation(roofLeft);
      }

      &--roof-right {
        width: $boundW;
        @include animation(roofRight);
      }
    }
  }
}