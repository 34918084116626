.headingBanner {
    min-height: 300px;
    padding: 80px 10px;
    display: flex;
    align-items: center;
    background-position: center;
    background-size: cover;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        top: 0;
        display: block;
        background-color: #003e52;
        opacity: 60%;
    }

    &__content {
        position: relative;
        z-index: 1;
    }

    &__title {
        color: #fff;
        margin: 0;
        font: italic normal bold 48px/56px Playfair Display;
    }

    .MuiBreadcrumbs-ol {
        margin: 0 0 18px;

        a {
            cursor: pointer;
        }

        a,
        li,
        p {
            font: normal normal normal 16px/19px Lato;
            letter-spacing: 1.6px;
            color: #FFFFFFCB;
            text-transform: uppercase;
            text-decoration: none;
        }
    }
}

.contactForm {
    padding: 80px 10px;
    background-color: #f5f5f5;

    .emojiBg {
        bottom: 0;
        left: -20%;
        position: absolute;
        z-index: 0;
    }

    .container {
        position: relative;
    }

    &__heading {
        max-width: 100%;
        margin: 0 auto;
        margin-bottom: 40px;

        p {
            font: normal normal normal 14px/22px Lato;
            color: #444;
            margin: 15px 0 0;
        }
    }

    &__title {
        color: #333;
        margin: 0;
        font: italic normal bold 36px/44px Playfair Display;
    }

    form {
        max-width: 100%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 22px;
        position: relative;
        z-index: 11;


        .MuiFormControl-root {
            width: 100%;

            &:first-child,
            &:nth-child(2) {
                width: calc(50% - 11px);
            }

            .MuiInputBase-root {
                padding: 0;
            }

            .MuiInputBase-input {
                background: #fff;
                border-radius: 0;
                font: normal normal normal 14px/22px Lato;
                color: #444;
                padding: 15px;
            }

            .MuiFormLabel-root {
                font: normal normal normal 14px/22px Lato;
                color: #666;
            }

            fieldset {
                border-radius: 0;
                border: none;
            }
        }

        button {
            background: #bd4f19;
            border: none;
            padding: 15px 25px;
            border-radius: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            min-width: 150px;

            &:disabled {
                opacity: 50%;
            }
        }
    }

    .CustomCheckBox {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 11;
    }
}

.mediaContentBlock {
    padding: 100px 0;
    background: #bdd8d9;

    &__inner {
        display: flex;
        align-items: center;
    }

    &__image {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 50%;

        img {
            width: 150%;
            max-width: 150%;
            margin-left: -50%;
            min-height: 415px;
            object-fit: cover;
            box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
        }
    }

    &__content {
        background: #fff;
        padding: 25px;
        position: relative;
        z-index: 1;

        p {
            font: normal normal normal 14px/20px Lato;
            color: #444;
            margin: 8px 0 0;
        }
    }

    &__title {
        color: #333;
        margin: 0;
        font: italic normal bold 28px/34px Playfair Display;
    }

    &__subTitle {
        color: #333;
        margin: 0;
        font: italic normal bold 18px/22px Playfair Display;
        margin-top: 18px;
    }

    &--right {
        .mediaContentBlock__inner {
            flex-direction: row-reverse;
        }

        .mediaContentBlock__image img {
            margin-left: 0;
        }
    }
}

.videoBlock {
    background-color: #e6f4f6;
    padding: 100px 10px;

    &__inner {
        box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
        max-width: 100%;
        width: 100%;
        margin: 0 auto;

        &>div {
            width: 100% !important;
            height: 500px !important;
        }

        iframe {
            width: 100%;
        }

        img {
            width: 100%;
        }
    }

    iframe {
        height: 450px !important;
    }
}

@media (max-width: 991px) {
    .serviceListBlock {
        .serviceList:nth-child(even) {
            .serviceList__imgBlock {
                order: 0;
            }

            .serviceList__info {
                text-align: left;
            }
        }

        .serviceList__img {
            margin-bottom: 22px;
        }
    }

    .videoBlock {
        padding: 80px 10px;

        iframe {
            height: 350px !important;
        }
    }

    .contactForm form .MuiFormControl-root:first-child,
    .contactForm form .MuiFormControl-root:nth-child(2) {
        width: 100%;
    }

    .mediaContentBlock {
        padding: 80px 10px;

        &__inner {
            flex-direction: column !important;
        }

        &__content {
            order: 1;
            max-width: 90%;
            margin-top: -50px;
            padding: 20px;
        }

        &__image img {
            width: 100%;
            max-width: 100%;
            margin-left: 0;
            min-height: 300px;
        }
    }
}

@media (max-width: 767px) {
    .heroBanner__title {
        font-size: 35px;
        line-height: 50px;
    }

    .headingBanner {
        min-height: 255px;
        padding: 60px 10px;

        .MuiBreadcrumbs-ol {
            margin: 0 0 10px;
        }

        &__title {
            font-size: 40px;
            line-height: 55px;
        }
    }

    .videoBlock__inner>div {
        height: 275px !important;
    }

    .videoBlock iframe {
        height: 260px !important;
    }
}

.detailSection {
    float: clear;
    width: 300px;
    margin-top: 15px;

    .detailHeader {
        width: 120px;
        font-weight: bold;
        float: left;
        vertical-align: top;
    }

    .detailValue {
        float: left;
        vertical-align: top;
    }

}

.thankYouPage {
    text-align: center;
    padding: 80px 0;

    svg {
        fill: #1ecad3;
        margin-bottom: 50px;
    }

    h1 {
        font-size: 36px;
        text-transform: capitalize;
        margin-bottom: 18px;
        font-weight: 700;
        color: #003e52;
    }

    p {

        font-size: 18px;
        text-transform: capitalize;
        margin-bottom: 40px;
        color: #333333;

    }

    button {
        background: linear-gradient(90deg, rgb(189, 79, 25) 0%, rgb(104, 52, 49) 100%, rgb(104, 52, 49) 100%);
        color: #fff;
        padding: 10px 30px;
        text-align: center;
        border-radius: 50px;
        font-weight: 700;
        text-transform: capitalize;
        font-size: 16px;
        min-width: 170px;
    }
}

@media (max-width: 400px) {
    .heroBanner__title {
        font-size: 32px;
        line-height: 45px;
    }

    .detailSection .detailHeader {
        width: 105px;
    }
}