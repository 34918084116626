.contentModule {
    padding: 140px 20px;

    &__inner {
        max-width: 800px;
        margin: 0 auto;

        p {
            font: normal normal normal 16px/19px Lato;
            letter-spacing: 0px;
            color: #333333;
            margin: 0 0 25px;
        }
    }

    &__title {
        font: italic normal bold 40px/53px Playfair Display;
        letter-spacing: 0px;
        color: #333333;
        margin: 0 0 15px;
    }

    &__subTitle {
        font: normal normal bold 28px/40px Garamond Premier Pro;
        letter-spacing: 0px;
        color: #333333;
        margin: 0 0 15px;
    }
}

@media (max-width: 1400px) {
    .contentModule {
        padding: 110px 10px;
    }
}

@media (max-width: 991px) {
    .contentModule {
        padding: 80px 10px;

        &__title {
            font-size: 34px;
            line-height: 48px;
        }

        &__subTitle {
            font-size: 26px;
            line-height: 36px;
        }
    }
}